<!--
 * @Descripttion:
 * @version: X1.1
 * @Author: 李波
 * @Date: 2020-05-14 11:30:20
 * @LastEditors: 李波
 * @LastEditTime: 2020-05-29 10:13:36
 -->
<template>
  <div class="container">
    <div class="center">
      <div class="title">
      尊敬的老师：
    </div>
    <div class="text">
      <p>
        您好！
      </p>
      <p>
        该产品为本平台特约产品，在客户投保成功后，请您联系服务人员并提供【投保人姓名+保单号】，以确认推广费正常发放。
      </p>
    </div>
    <div class="btn">
      <div class="btn_left" @click="jhsclick" v-if="type&&qd">制作计划书</div>
      <div class="btn_right" @click="cpclicl">查看产品</div>
    </div>
    <div class="wx_img" v-if="qd">
        <img src="@/assets/agent/img/h5_ts.jpg" alt="">
    </div>
    <div class="text_bottom" v-if="qd">
      <p>请扫描上方二维码联系客服</p>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      url: 'http://mobile.ihxlife.com/mobile/#/forecastyryzh?TYPE=16&userid=1615013709269&sales_agent=477201034&third_orgnization=724701721034&user_name=%E6%A2%81%E7%A7%8B%E7%90%AA%28%E5%8D%97%E5%8D%8E%E9%A1%B9%E7%9B%AE%29&mobile=15013709269',
      type: '',
      qd: true,
    }
  },
  created () {
    if(this.$route.query.htype) {
      this.type = this.$route.query.htype
      this.url = 'http://mobile.ihxlife.com/mobile/#/forecastcct/024097027e59465385b2d3fca69a4f3c?TYPE=16&userid=1615013709269&sales_agent=477201034&third_orgnization=724701721034&user_name=%E6%A2%81%E7%A7%8B%E7%90%AA%28%E5%8D%97%E5%8D%8E%E9%A1%B9%E7%9B%AE%29&mobile=15013709269'
    }
    if(this.$route.query.qd) {
      this.qd = false
    }
    // if(window.location.hostname.includes('nhwx')) {
    //   this.qd = true
    // }
  },
  methods: {
    // 计划书跳转链接
    jhsclick () {
      if(this.qd == false) {
        window.location.href = `https://qd.cv.hckjcx.com/makeout?cpcode=${this.$route.query.prodcode}&baseid=''&type=CP&onetype=true`
      } else {
        window.location.href = `https://${window.location.hostname}/makeout?cpcode=${this.$route.query.prodcode}&baseid=''&type=CP&onetype=true`
      }
      // http://nhsx.devnhcv.hcpaiahk.com/makeout?baseid=JKRS&cpcode=CP1231409959206563842&type=CP&fromwhere=
    },
    // 产品跳转链接
    cpclicl () {
      let url;
      // 常春藤（多倍版）
      // url = 'http://mobile.ihxlife.com/mobile/#/forecastcct/024097027e59465385b2d3fca69a4f3c?TYPE=16&userid=1615013709269&sales_agent=477201034&third_orgnization=724701721034&user_name=%E6%A2%81%E7%A7%8B%E7%90%AA%28%E5%8D%97%E5%8D%8E%E9%A1%B9%E7%9B%AE%29&mobile=15013709269'
      // 玉如意
      // url = 'http://mobile.ihxlife.com/mobile/#/forecastyryzh?TYPE=16&userid=1615013709269&sales_agent=477201034&third_orgnization=724701721034&user_name=%E6%A2%81%E7%A7%8B%E7%90%AA%28%E5%8D%97%E5%8D%8E%E9%A1%B9%E7%9B%AE%29&mobile=15013709269'
      window.location.href = this.url

    }
  }
}
</script>

<style  scoped>
.center{
  width: 95%;
  margin: 0px auto;
  overflow: auto;
}
.center .title {
  margin: 0.4rem 0px 0.7rem 0.4rem;
}
.center .text {
  margin-left: 0.4rem;
}
.text p{
  text-indent: 0.8rem;
}
.btn{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  color: #ffffff;
}
.btn div {
  width: 40%;
  background-color: #FB6822;
  height: 1.2rem;
  border-radius: 0.5rem;
  margin: 0px 0.5rem;
  text-align: center;
  line-height: 1.2rem;
  font-size: 0.45rem;
}
.wx_img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.wx_img img{
  width: 3.6rem;
  height: 3.6rem;
}
.text_bottom {
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
}
.text_bottom p {
  width: 3.4rem;
  text-align: center;
  font-size: 0.42rem;
  color: #999;

}
</style>

